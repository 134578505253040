<template>
  <!-- 底部 -->
  <footer>
    <div class="bottom-imgs">
      <img class="footer-logo" :src="require('@/imgs/logo 拷贝 2.png')" @click="toHome" />
    </div>
    <a href="https://beian.miit.gov.cn "><img class="sheld" :src="require('@/imgs/图层 105.png')" alt="" /></a>
    <div class="middle-msgs">
      <div>
        <span>版权所有：连云港市革命纪念馆</span>
        <span>
          <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=32070602010094">
            <img :src="require('@/imgs/苏公网安备.png')" alt="">
            <span>苏公网安备 32070602010094号</span>
          </a>
        </span>
      </div>
      <div>
        <span>地址：连云港市海州区朝阳东路70号</span>
        <span>联系电话：0518-85820610</span>
      </div>
      <div>
        <span>
          <a href="http://www.jsxinyuansu.com/">
            <span>技术支持：新元素数字科技有限公司</span>
          </a>
        </span>
        <span>苏ICP备2023013855号</span>
      </div>
    </div>
    <div class="wxgzh-all">
      <div class="wxgzh" >
        <img :src="require('@/imgs/wxewm.png')" alt="" />
        <p>微信公众号</p>
      </div>
    </div>
  </footer>
  <div class="modelBtm">
    <span class="msg">
      <p>版权所有：连云港市革命纪念馆</p>
      <p>苏ICP备2023013855号</p>
    </span>
  </div>
</template>
<script>
export default {
  name: "FootPart",
  props: {
    msg: String,
  },
  data() {
    return {
      lang: ''
    }
  },
  beforeMount() {
    this.lang = localStorage.getItem('langtype')
  },
  methods: {
    toHome() {
      if (this.lang == 'cn') {
        this.$router.push('/')
      } else {
        this.$router.push('/foreignlang')
      }
    }
  }
};
</script>
<style scoped>
/* 底部信息 */
footer {
  /* height: 200px; */
  padding: 5% 0;
  box-sizing: border-box;
  background-image: url("@/imgs/图层 49.png");
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  position: relative;
}

.footer-logo {
  width: 25%;
  position: absolute;
  left: 10%;
  top: 30%;
}

.sheld {
  width: 50px;
  height: 60px;
  position: absolute;
  top: 33%;
  left: 33%;
}

.middle-msgs {
  width: 35%;
  /* height: 100px; */
  margin-left: 10px;
  overflow: hidden;
  /* background-image: url('../imgs/cnFooter.png'); */
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  top: 30%;
  left: 38%;
}

.middle-msgs div {
  width: 100%;
  display: flex;
  justify-content: space-around;
  text-align: left;
  margin: 5px 0;
}

.middle-msgs div span {
  width: 55%;
  /* background-color: #fff; */
  text-align: left;
  font-size: 18px;
  position: relative;
  font-weight: 400;
  color: #fff;
  white-space: nowrap;

}

.middle-msgs div span img {
  position: absolute;
  top: 0;
  left: -25px;
}

.wxgzh-all {
  position: absolute;
  top: 20%;
  left: 80%;
  display: flex;
}

.wxgzh {
  margin: 0 5px;
}

.wxgzh img {
  width: 100%;
  /* height: 75%; */
  display: block;
  margin: 0 auto 5px;
}

.wxgzh p {
  width: 100%;
  height: 15px;
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #f7f5f5;
  line-height: 18px;
  text-align: center;
}

.modelBtm {
  width: 100%;
  height: 25%;
  position: static;
  bottom: 0;
  display: none;
  background-image: url('../imgs/底部背景.png');
}


.msg {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding-top: 15%;
  padding-bottom: 30px;
  box-sizing: border-box;
}

.msg p {
  width: 100%;
  display: block;
  text-align: center;
  margin: 5px 0;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #F7F5F5;
  line-height: 18px;
}



@media screen and (min-width:0px) and (max-width: 1000px) {
  footer {
    display: none;
  }

  .modelBtm {
    display: block;
  }
}

@media screen and (min-width:1000px) and (max-width: 1600px) {
  .sheld{
    width: 35px;
    height:40px;
  }
  .middle-msgs {
    width: 45%;
    /* height: 100px; */
    margin-left: 10px;
    overflow: hidden;
    /* background-image: url('../imgs/cnFooter.png'); */
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    top: 20%;
    left: 35%;
  }

  .middle-msgs div span {
    width: 45%;
    white-space: nowrap;
    /* background-color: #fff; */
    text-align: left;
    font-size: 13px;
    position: relative;
    font-weight: 400;
    color: #fff;

  }

  .wxgzh-all {
    /* width: 25%; */
    position: absolute;
    top: 15%;
    left:80%;
    display: flex;
  }

  .wxgzh img {
    width: 60%;
    /* height: 75%; */
    display: block;
    margin: 0 auto;
  }

  .wxgzh p {
    width: 100%;
    height: 15px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #f7f5f5;
    line-height: 18px;
    text-align: center;
  }

}</style>