<template>
  <NavVue />
  <!-- 轮播图 -->
  <div class="rounder">
    <div class="imgs" ref="imgBox">
      <img v-for="(item, index) in picArr" :key="index" class="round-imgs" ref="rimgs" :src="item.savePath" />
    </div>
  </div>
  <travell class="comTime" />
  <!-- 公告栏 -->
  <div class="announcement">
    <div class="announcement-left">
      <div class="left-title">
        <div class="title-all">
          <img class="title-left" :src="require('@/imgs/图层 51.png')" />
          <div class="title-right">
            <h2>公告栏</h2>
            <p class="announcement-more" @click="moreTip()">更多>></p>
          </div>
        </div>
        <div class="title-bottom">
          <span class="announcement-items" v-for="(item, a) in noticeList" :key="a" @click="getAtc(item)">
            <img class="items-circle" :src="require('@/imgs/椭圆 3.png')" />
            <p class="notice-title">{{ item.title }}</p>
            <p class="items-date">{{ item.createTime }}</p>
          </span>
        </div>
      </div>
    </div>
    <div class="announcement-right">
      <div class="visit-tips">
        <!-- <img :src="require('@/imgs/参观指南.png')" /> -->
        <h2 class="tip-title">参观指南</h2>
        <span class="tip-msg">连云港市革命纪念馆位于连云港市海州区朝阳东路70号，每周二至周日开放，周一闭馆。免费参观，进馆免预约。可乘坐b12路在市政府站下车即可。</span>
        <span class="tip-time">
          <span class="times">
            <h2>9:00</h2>
            <p>开馆时间</p>
          </span>
          <span class="tip-line"></span>
          <span class="times">
            <h2>17:00</h2>
            <p>闭馆时间</p>
          </span>
        </span>
        <div class="bookCall" style="margin-top: 20px;">
          <div class="test">
            <div class="phone">
              <span>联系电话:</span>
              <span>0518-85820610</span>
              <span>0518-85820390</span>
            </div>
            <div class="phone">
              <span>人工讲解预约电话:</span>
              <span>0518-85801802</span>
            </div>
          </div>
          <!-- <div class="new-phone">
            <span>学习贯彻习近平新时代中国特色社会主义思想主题教育“实境课堂”预约电话:</span>
            <span>0518-85801802</span>
          </div> -->
        </div>
      </div>
      <div class="serves">
        <!-- <a href="http://jqyy.lygjng.com/index/index/yy_wxjng">
          <img :src="require('@/imgs/yyfw.png')" alt="" />
        </a> -->
        <span style="cursor: pointer;" @click="reservationService=true">
          <img :src="require('@/imgs/yyfw.png')" alt="" />
        </span>
        <!-- <a href="http://wjyl.lygjng.com/lygjng">
          <img :src="require('@/imgs/qmjyl.png')" alt="" />
        </a> -->
        <a href="http://www.backlightvr.com/tour/0738e24d171e6b6a">
          <img :src="require('@/imgs/图层 121.png')" alt="" />
        </a>
      </div>
    </div>
  </div>

  <!-- 栏目轮播 -->
  <!-- <img style="width: 82vw;display: block; margin: 1vw auto;" src="../imgs/middleImg.jpg" alt=""> -->
  <!-- <img class="small-arrows"  /> -->
  <div class="swiperBox">
    <img class="swiperImg" v-for="(item, test) in 6" :key="test" :src="require(`@/imgs/swiper${test + 1}.jpg`)" alt=""
      @click="routeLink(test)">
    <div class="pageBox">
      <p class="swiperNum" @mouseenter="changeSwiper(stest)"
        v-for="(item, stest) in 6" :key="stest">{{ stest + 1 }}</p>
    </div>
  </div>


  <!--新闻部分 -->
  <div class="new-message">
    <div class="news">
      <div class="roundBox">
        <div class="testbox" ref="newsImgs">
          <div class="left-imgs" ref="smallmsg" v-for="(item, i) in newsImg.splice(0, 4)" :key="i">
            <img class="left-round" :src="item.coverImage" ref="testSize" @click="toSingle(item)" />
            <span class="img-msgs">{{ item.title }}</span>
          </div>
        </div>
      </div>
      <div class="right-msgs">
        <div class="tab-change">
          <span class="tab-news">工作动态</span>
          <div class="mobile-more" @click="moreMsg(newsList)">更多>></div>
        </div>
        <div class="tab-messages">
          <div class="tabmsg-item" v-for="(item, i) in newsList" :key="i">
            <div class="item-msg" @click="toSingle(item)">
              <div class="msg-left">
                <img :src="item.coverImage" alt="" />
              </div>
              <div class="msg-right">
                <img class="left-arrow" :src="require('@/imgs/图层 22.png')" />
                <span>{{ item.title }}</span>
              </div>
            </div>
            <div class="item-date">{{item.createTime }}</div>
          </div>
        </div>
      </div>
      <div class="more" @click="moreMsg(newsList)">
        <img :src="require('@/imgs/查看更多.png')" />
      </div>
    </div>
  </div>

  <!-- 图片箱 -->
  <div class="img-box">
    <div class="reclics">
      <div class="reclic-imgs" v-for="(item, f) in relicsList" :key="f" @click="relics(item)">
        <img :src="item.coverImage" alt="" />
        <span class="img-title">{{ item.title }}</span>
      </div>
    </div>
  </div>
  <hisView />
  <!-- 学习园地 -->
  <div class="study-place">
    <h2 class="boxTit">寻红·红巡</h2>
    <div class="changeTab">
      <span :class="act == index ? 'atc' : ''" v-for="(item, index) in tabChange" :key="index" @click="changeTab(index)"
        ref="tab">{{ item }}</span>
    </div>
    <div class="top-place">
      <div class="study-items" ref="study">
        <div class="study-title">
          <span class="instudy-title" ref="stydyBox">宣讲</span>
          <span class="study-more" @click="moreStudy()">更多+</span>
        </div>
        <div class="big-item" v-for="(item, index) in studyList" :key="index" v-show="index == 0" @click="getAtc(item)">
          <img v-if="index == 0" :src="item.coverImage" />
          <span class="item-msgs">
            <h2>{{ item.title }}</h2>
            <p>{{ item.description }}</p>
          </span>
        </div>
        <div class="small-items">
          <div class="items-list" v-for="(item, index) in studyList" :key="index" v-show="index != 0"
            @click="getAtc(item)">
            <img class="small-arrows" :src="require('@/imgs/图层 88 拷贝.png')" />
            <div class="sitems-msg">
              <span>{{ item.title }}</span>
              <span>{{ item.createTime }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="study-items" ref="preach">
        <div class="study-title">
          <span class="instudy-title" ref="soclityBox">陈展</span>
          <span class="study-more" @click="moreSoclity()">更多+</span>
        </div>
        <div class="big-item" v-for="(item, index) in societyList" :key="index" v-show="index == 0" @click="getAtc(item)">
          <img v-if="index == 0" :src="item.coverImage" />
          <span class="item-msgs">
            <h2>{{ item.title }}</h2>
            <p>{{ item.description }}</p>
          </span>
        </div>
        <div class="small-items">
          <div class="items-list" v-for="(item, index) in societyList" :key="index" v-show="index != 0"
            @click="getAtc(item)">
            <img class="small-arrows" :src="require('@/imgs/图层 88 拷贝.png')" />
            <div class="sitems-msg">
              <span>{{ item.title }}</span>
              <span>{{ item.createTime }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="construction" ref="commonweal">
        <div class="study-title">
          <span class="instudy-title">小推车公益</span>
          <span class="study-more" @click="moreCart">更多+</span>
        </div>
        <div class="construction-imgs" @click="moreCart">
          <div class="img-detail">
            <img :src="require('@/imgs/图层 94.png')" />
            <img :src="require('@/imgs/图层 95.png')" />
          </div>
          <div class="img-detail">
            <img :src="require('@/imgs/图层 96.png')" />
            <img :src="require('@/imgs/图层 97.png')" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- 旅游服务 -->
  <div class="tourism" @click="toList">
    <div class="tourism-title">
      <h1 ref="tourItem">旅游服务</h1>
      <p>TOURISM SERVICEST</p>
    </div>
    <div class="plays">
      <div class="plays-item">
        <img :src="require('@/imgs/图层 72.png')" />
        <p>食</p>
      </div>
      <div class="plays-item">
        <img :src="require('@/imgs/图层 74.png')" />
        <p>住</p>
      </div>
      <div class="plays-item">
        <img :src="require('@/imgs/图层 76.png')" />
        <p>行</p>
      </div>
      <div class="plays-item">
        <img :src="require('@/imgs/图层 77.png')" />
        <p>游</p>
      </div>
      <div class="plays-item">
        <img :src="require('@/imgs/图层 78.png')" />
        <p>购</p>
      </div>
      <div class="plays-item">
        <img :src="require('@/imgs/图层 79.png')" />
        <p>娱</p>
      </div>
    </div>
  </div>
  <!-- 浮窗 左侧-->
  <div class="windowPic float-left" v-if="picShowLeft">
    <div class="close" @click="closePic('picShowLeft')">x</div>
    <img :src="require('@/imgs/侧边.jpg')" alt="" @click="toNewlist()" />
  </div>
  <!-- 浮窗 右侧-->
  <div class="windowPic float-right" v-if="picShowRight">
    <div class="close" @click="closePic('picShowRight')">x</div>
    <img :src="require('@/imgs/侧边右侧.jpg')" alt="" @click="toNewlist()" />
  </div>
  <!-- 预约服务弹框 -->
  <div class="bg" v-if="reservationService">
    <div class="close close-yy" @click="closePic('reservationService')">x</div>
    <div class="windowPic center-yy">
      <img :src="require('@/imgs/yyfw.jpg')" alt="" @click="toNewlist()" />
    </div>
  </div>
  
  <FooterVue />
</template>
<script>
import { defineComponent, defineAsyncComponent } from "vue";
const NavVue = defineAsyncComponent(() => import("@/components/Nav.vue"));
// const FooterVue = defineAsyncComponent(() => import("@/components/Footer.vue"));
import travell from "../components/Mobiletime.vue";
import FooterVue from "../components/Footer.vue";
import hisView from "../components/Mobilehis.vue";
import axios from "axios";
export default defineComponent({
  components: {
    NavVue,
    FooterVue,
    travell,
    hisView,
  },
  data() {
    return {
      dialogVisible: false,
      left: 0,
      left1: 0,
      newsList: [],
      noticeList: [],
      studyList: [],
      societyList: [],
      relicsList: [],
      tabChange: ["宣讲", "陈展", "小推车公益"],
      picArr: [],
      act: 0,
      data: '',
      testTime: '',
      windowPic: '',
      picShowRight: true,
      picShowLeft: true,
      reservationService: false,
      newsImg: [],
      swiperImgs: 0
    };
  },
  mounted() {
        axios.post(`${process.env.VUE_APP_BASE_API}/door/ArticleList`).then((res) => {
      localStorage.setItem("atcList", JSON.stringify(res.data.rows));
      this.data = res.data.rows
      this.getData();
    });
    axios.post(`${process.env.VUE_APP_BASE_API}/door/MaterialList`).then((res) => {
      let data = res.data.rows
      data.forEach(element => {
        if (element.groupName == '首页轮播图') {
          element.savePath = `${process.env.VUE_APP_BASE_API}` + element.savePath
          this.picArr.push(element)
        } else if (element.groupName == '主页飘窗') {
          element.savePath = `${process.env.VUE_APP_BASE_API}` + element.savePath
          this.windowPic = element.savePath
        }

      });
      this.picArr.splice(2, 1)
      let testNum = this.picArr.length
      this.$refs.imgBox.style.width = `${(testNum) * 105}` + '%'
    }).then(() => {
      this.Round(), this.headRound()
    })
    document.querySelectorAll('.swiperImg')[this.swiperImgs].style.display = 'block'
    document.querySelectorAll('.swiperNum')[this.swiperImgs].style.color = 'gray'

    setInterval(() => {
      if (this.swiperImgs < document.querySelectorAll('.swiperImg').length - 1) {
        this.swiperImgs = this.swiperImgs + 1
        this.changeSwiper(this.swiperImgs)

      } else if (this.swiperImgs == document.querySelectorAll('.swiperImg').length - 1) {
        this.swiperImgs = 0
        this.changeSwiper(this.swiperImgs)

      }

    },3500);


  },
  methods: {
    openDialogUrl(){
      console.log(111)
      this.dialogVisible = true
    },
    toSingle(item) {
      let testList = JSON.parse(localStorage.getItem('tree'))
      let tag = item.category.parentName == null ? item.category.categoryName : item.category.parentName
      localStorage.setItem('indexList', tag)
      testList.forEach(element => {
        if (element.categoryName == '工作动态') {
          sessionStorage.setItem('listData', JSON.stringify(element))
          this.$router.push(`/essay/${item.id}`).then(() => {
            location.reload()
          });
        }
      });
      axios.post(`${process.env.VUE_APP_BASE_API}/door/increaseQuantity?id=${item.id}`);
    },
    getData() {
      let dataarr = [];
      let noticeArr = [];
      let studyArr = [];
      let societyArr = [];
      let relicsArr = [];
      let topArr = []
      this.data.forEach((element) => {
        if (element.categoryId == "2" && element.coverImage != '' && element.coverImage != null) {
          if(element.coverImage.indexOf('http')==-1){
            element.coverImage = `${process.env.VUE_APP_BASE_API}` + element.coverImage
          }
          this.newsImg.push(element)
          
        }
        if (element.categoryId == 2 && element.topFlag == 1) {
          element.createTime = element.createTime.split(" ")[0];
          dataarr.push(element);
        }
        if (element.categoryId == "25") {
          if (element.topFlag == '1') {
            topArr.push(element)
          }
          element.createTime = element.createTime.split(" ")[0];
          element.testTime = Date.parse(element.createTime);
          noticeArr.push(element);
        }
        if (element.categoryId == "6" && element.coverImage != "") {
          element.createTime = element.createTime.split(" ")[0];
          element.coverImage = `${process.env.VUE_APP_BASE_API}` + element.coverImage;
          studyArr.push(element);
        }
        if (element.categoryId == "5" && element.coverImage != "") {
          element.createTime = element.createTime.split(" ")[0];
          element.coverImage = `${process.env.VUE_APP_BASE_API}` + element.coverImage;
          societyArr.push(element);
        }
        if (
          element.categoryId == "4" &&
          element.coverImage != "" &&
          element.tags == "50"
        ) {
          element.createTime = element.createTime.split(" ")[0];
          element.coverImage = `${process.env.VUE_APP_BASE_API}` + element.coverImage;
          relicsArr.push(element);
        }
      });
      this.newsList = dataarr.splice(0, 5);
      noticeArr[0] = topArr[0]
      noticeArr.sort(function (a, b) {
        return b.testTime - a.testTime;
      });
      this.noticeList = noticeArr.splice(0, 6);
      this.studyList = studyArr.splice(1, 6);
      this.societyList = societyArr.splice(0, 6);
      this.relicsList = relicsArr.splice(0, 3);
      localStorage.setItem("newsList", JSON.stringify(this.newsList));
      localStorage.setItem("noticeList", JSON.stringify(this.noticeList));
      localStorage.setItem("studyList", JSON.stringify(this.studyList));
      localStorage.setItem("societyList", JSON.stringify(this.societyList));
      localStorage.setItem("relicsList", JSON.stringify(this.relicsList));
    },
    getAtc(item) {
      console.log(item)
      let testList = JSON.parse(localStorage.getItem('tree'))
      let tag = item.category.parentName == null ? item.category.categoryName : item.category.parentName
      localStorage.setItem('indexList', tag)
      testList.forEach(element => {
        if (element.categoryName == tag) {
          sessionStorage.setItem('listData', JSON.stringify(element))
          
        }
      });
      this.$router.push(`/essay/${item.id}`)
      axios.post(`${process.env.VUE_APP_BASE_API}/door/increaseQuantity?id=${item.id}`);

    },
    moreTip() {
      let testList = JSON.parse(localStorage.getItem('tree'))
      testList.forEach(element => {
        if (element.categoryName == '公告栏') {
          sessionStorage.setItem('listData', JSON.stringify(element))
          this.$router.push(`/essaylist`).then(() => {
            location.reload();
          });
        }
      });

    },
    moreStudy() {
      let testArr = JSON.parse(localStorage.getItem('tree'))
      testArr.forEach(element => {
        if (element.categoryName == '寻红·红巡') {
          element.testindex = 0
          sessionStorage.setItem('listData', JSON.stringify(element))
          this.$router.push(`/essaylist`).then(() => {
            location.reload();
          });
        }
      });
    },
    moreSoclity() {
      let testArr = JSON.parse(localStorage.getItem('tree'))
      testArr.forEach(element => {
        if (element.categoryName == '寻红·红巡') {
          element.testindex = 1
          sessionStorage.setItem('listData', JSON.stringify(element))
          this.$router.push(`/essaylist`).then(() => {
            location.reload();
          });
        }
      });
    },
    moreCart() {
      let testArr = JSON.parse(localStorage.getItem('tree'))
      testArr.forEach(element => {
        if (element.categoryName == '寻红·红巡') {
          element.testindex = 2
          sessionStorage.setItem('listData', JSON.stringify(element))
          this.$router.push(`/essaylist`).then(() => {
            location.reload();
          });
        }
      });
    },

    Round() {
      setInterval(() => {
        let width = this.$refs.smallmsg.clientWidth;
        if (this.left < width * 3) {
          this.left += width;
          this.$refs.newsImgs.style.left = -this.left + "px";
        } else if ((this.left = width * 3)) {
          this.left = 0;
          this.$refs.newsImgs.style.left = -this.left + "px";
        }
      }, 3000);
    },
    moreMsg() {
      let testList = JSON.parse(localStorage.getItem('cnList'))
      testList.forEach(element => {
        if (element.categoryName == this.newsList[0].category.categoryName) {
          sessionStorage.setItem('listData', JSON.stringify(element))
          this.$router.push(`/essaylist`).then(() => {
            location.reload();
          });
        }
      });

    },
    relics(item) {
      let testList = JSON.parse(localStorage.getItem('tree'))
      let tag = item.category.parentName == null ? item.category.categoryName : item.category.parentName
      localStorage.setItem('indexList', tag)
      testList.forEach(element => {
        if (element.categoryName == '文物') {
          sessionStorage.setItem('listData', JSON.stringify(element))
          this.$router.push(`/essay/${item.id}`).then(() => {
            location.reload()
          });
        }
      });
      axios.post(`${process.env.VUE_APP_BASE_API}/door/increaseQuantity?id=${item.id}`);
    },
    headRound() {
      let testNum = this.picArr.length - 1
      setInterval(() => {
        let width = this.$refs.rimgs.clientWidth;
        if (this.left1 < width * testNum) {
          this.left1 += width;
          this.$refs.imgBox.style.left = -this.left1 + "px";
        } else if (this.left1 = width * testNum) {
          this.left1 = 0;
          this.$refs.imgBox.style.left = -this.left1 + "px";
        }
      }, 7000);
    },
    toList() {
      let keyWord = this.$refs.tourItem.innerText;
      let testArr = JSON.parse(localStorage.getItem('tree'))
      testArr.forEach(element => {
        if (element.categoryName == keyWord) {
          sessionStorage.setItem('listData', JSON.stringify(element))
          this.$router.push(`/essaylist`).then(() => {
            location.reload();
          });
        }
      });
    },
    toNewlist() {
      let keyWord = '主题教育栏目';
      let testArr = JSON.parse(localStorage.getItem('tree'))
      console.log(testArr)
      testArr.forEach(element => {
        if (element.categoryName == keyWord) {
          sessionStorage.setItem('listData', JSON.stringify(element))
          this.$router.push(`/essaylist`).then(() => {
            location.reload();
          });
        }
      });
    },
    closePic(str) {
      this[str] = !this[str]
    },
    changeTab(index) {
      this.act = index;
      if (index == 0) {
        this.$refs.study.style.display = "block";
        this.$refs.preach.style.display = "none";
        this.$refs.commonweal.style.display = "none";
      }
      if (index == 1) {
        this.$refs.study.style.display = "none";
        this.$refs.preach.style.display = "block";
        this.$refs.commonweal.style.display = "none";
      }
      if (index == 2) {
        this.$refs.study.style.display = "none";
        this.$refs.preach.style.display = "none";
        this.$refs.commonweal.style.display = "block";
      }
    },
    changeSwiper(num) {
      let arr = document.querySelectorAll('.swiperImg')
      let swiperarr = document.querySelectorAll('.swiperNum')
      arr.forEach(ele => {
        ele.style.display = 'none'
      })
      swiperarr.forEach(ele => {
        ele.style.color = 'gray'
      })
      this.swiperImgs = num
      document.querySelectorAll('.swiperImg')[num].style.display = 'block'
      document.querySelectorAll('.swiperNum')[num].style.color = 'black'
    },
    routeLink(num) {
      if (num == 0) {
        window.open('http://www.jsdsw.org.cn/web/more/more.html?id=86')
      } else if (num == 1) {
        window.open('http://www.lyg01.net/dsxxjy/')
      }
    }
  },
});
</script>
<style scoped>
* {
  margin: 0;
  padding: 0;
  overflow: hidden;
  list-style: none;
  text-decoration: none;
}

.swiperBox {
  position: relative;
}

.swiperImg {
  display: none;
  width: 85vw;
  height: 8vw;
  /* display: block; */
  margin: 1vw auto;
}

.pageBox {

  display: flex;
  position: absolute;
  bottom: 1vw;
  right: 9vw;
}

.pageBox p {
  width: 1.5vw;
  height: 1.5vw;
  margin: 0 .1vw;
  font-size: 1vw;
  text-align: center;
  box-sizing: border-box;
  border: 1px solid #000;
  cursor: pointer;
}



/* 轮播 */
.rounder {
  width: 100%;
  /* height: 800px; */
  overflow: hidden;
}

.imgs {
  display: flex;
  position: relative;
  left: 0;
  transition: 1s all linear;
}

.round-imgs {
  width: 100%;
  /* height: 700px; */
  display: block;
}

/* 公告栏样式 */
.announcement {
  width: 100%;
  /* height: 600px; */
  display: flex;
  padding: 60px 8% 30px;
  position: relative;
  box-sizing: border-box;
  justify-content: space-around;
}

.announcement-left {
  width: 45%;
  /* height: 440px; */
  margin-right: 20px;
  background-color: #f5ebdc;
}

.left-title {
  padding: 20px 20px;
  box-sizing: border-box;
}

.title-all {
  display: flex;
}

.title-left {
  width: 33px;
  height: 26px;
  position: relative;
  top: 4px;
  margin-right: 10px;
}

.title-right {
  width: 90%;
  height: 50px;
  display: flex;
  position: relative;
}

.title-right h2 {
  width: 84px;
  height: 45px;
  font-size: 28px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #343434;
}

.announcement-more {
  position: absolute;
  right: 0;
  line-height: 30px;
  width: 54px;
  height: 30px;
  font-size: 16px;
  white-space: nowrap;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #6e6d6d;
  cursor: pointer
}

/* 公告条样式 */
.announcement-items {
  width: 100%;
  height: 20%;
  display: inline-flex;
  position: relative;
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #6e6d6d;
  padding: 15px 0;
  box-sizing: border-box;
  border-bottom: 2px dashed #cecece;
  cursor: pointer
}

.notice-title {
  width: 70%;
  height: 25px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.items-date {
  position: absolute;
  right: 0;
  /* width: 81px; */
  height: 25px;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #505050;
  line-height: 30px;
}

.items-circle {
  width: 11px;
  height: 11px;
  position: relative;
  top: 6px;
  margin-right: 10px;
}

/* 右部 */
.announcement-right {
  width: 50%;
  height: 440px;
  display: flex;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url("@/imgs/blueback.png");
}

.visit-tips {
  width: 60%;
  padding: 2% 4%;
}

.visit-tips img {
  width: 100%;
  height: 100%;
  display: block;
}

.tip-title {
  font-size: 28px;
  font-weight: 400;
  color: #fff;
}

.tip-msg {
  display: block;
  text-indent: 30px;
  width: 100%;
  /* height: 40%; */
  margin: 15px 0;
  font-size: 18px;
  font-weight: 400;
  color: rgb(149, 201, 220);
}

.tip-time {
  width: 60%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}

.tip-line {
  display: block;
  width: 20px;
  margin: 25px 10px 0;
  height: 0;
  border: 1px solid #ffffff;
}

.times h2 {
  font-size: 35px;
  color: #fff;
}

.times p {
  font-size: 18px;
  text-align: center;
  color: #edc07b;

}

.test {
  display: flex;
  justify-content: space-between;

}

.phone {
  margin: 2px 10px 2px 0;
}
.phone span{
 line-height: 32px;
}
.new-phone {
  margin-top: 10px;
}

.new-phone span {
  font-size: 20px;
  display: block;
  color: #ffff;
  /* margin-top: 10px; */
}

.new-phone span:nth-child(2) {
  font-size: 20px;
  margin-top: 5px;
}

.phone span {
  font-size: 20px;
  margin: 2px 10px 2px 0;
  display: block;
  color: #ffff;
}

.serves {
  width: 45%;
  /* height: inherit; */
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
  background-image: url("@/imgs/组 11.png");
}
.serves span{
  margin-bottom: 40px;
}

.serves img {
  display: block;
  width: 100%;
  /* height: 25%; */
  margin-bottom: 45px;
  border-radius: 5%;
  box-shadow: #333333 5px 5px 5px;
}

/* 新闻部分样式 */
.new-message {
  width: 100%;
  padding: 50px 7% 0;
  box-sizing: border-box;
  position: relative;
  top: -5px;

  background-color: rgb(240, 240, 240);
}

.news {
  width: 100%;
  overflow: hidden;
  height: 550px;
  display: flex;
  justify-content: space-between;
  position: relative;
}

/* 左部新闻样式 */
.roundBox {
  width: 44%;
  height: 77%;
  overflow: hidden;
  display: flex;
  flex-wrap: nowrap;
  position: relative;
  left: 30px;
  margin-right: 50px;
  text-overflow: ellipsis;
}

.testbox {
  height: 100%;
  display: flex;
  overflow: hidden;
  position: absolute;
  left: 0;
  transition: all 1s linear;
}

.left-imgs {
  width: 720px;
  height: 100%;
  display: block;
}

.left-round {
  width: 100%;
  height: 100%;
  display: inline-block;
}

.img-msgs {
  width: 720px;
  display: block;
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #e4e4e4;
  background-image: url("@/imgs/图层 21.png");
  position: absolute;
  bottom: 0px;
  padding: 11px 180px 8px 20px;
  box-sizing: border-box;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* 右部新闻列表 */
.right-msgs {
  width: 50%;
  height: 80%;
  position: relative;
  top: -20px;
  /* left: -170px; */
}

/* tab栏 */
.tab-change {
  width: 150px;
  height: 70px;
  display: flex;
  justify-content: space-between;
  padding-left: 50px;
  position: relative;
  left: -60px;
}

.tab-change>span {
  text-align: center;
  display: block;
  width: 90%;
  font-size: 28px;
  line-height: 72px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  overflow: hidden;
  text-overflow: ellipsis;

}

.tab-news {
  font-size: 26px;
  color: #9c1306;
  border-bottom: 4px solid #9c1306;
}

.mobile-more {
  position: absolute;
  right: 25px;
  top: 45px;
  line-height: 30px;
  width: 54px;
  height: 30px;
  font-size: 16px;
  white-space: nowrap;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #6e6d6d;
  display: none;
}

/* 新闻列表样式 */
.tab-messages {
  margin-top: 20px;
}

.item-msg {
  width: 100%;
  display: flex;
  white-space: nowrap;
  cursor: pointer
}

.msg-left {
  width: 40%;
  border-radius: 20px;
  display: none;
}

.msg-left img {
  width: 100%;
  height: 100%;
}

.msg-right {
  width: 100%;
}

.tabmsg-item {
  width: 100%;
  height: 80px;
  box-sizing: border-box;
  margin-bottom: 10px;
  border-bottom: 1px solid rgb(207, 207, 207);
}

.item-date {
  position: relative;
  top: -20px;
  left: 40px;
  color: #343434;
}

.left-arrow {
  display: inline-block;
}

.msg-right span {
  width: 90%;
  height: 30px;
  display: block;
  font-size: 24px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #343434;
  position: relative;
  top: -36px;
  left: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* 查看更多 */
.more {
  width: 145px;
  height: 50px;
  display: block;
  position: absolute;
  bottom: 40px;
  left: 45%;
  margin: 0 auto;
  cursor: pointer
}

/* 图片箱样式 */
.img-box {
  width: 80%;
  /* height: 650px; */
  overflow: hidden;
  box-sizing: border-box;
  /* padding: 40px 3% 0; */
  margin: 0 auto;
  margin-bottom: 50px;
}

/* 文物 */
.reclics {
  width: 100%;
  height: 80%;
  margin-top: 35px;
  background-image: url("@/imgs/文物巡礼 背景.png");
  background-size: cover;
  background-repeat: no-repeat;
  padding: 25px 30px 25px 15rem;
  box-sizing: border-box;
  display: flex;
}

.reclic-imgs {
  width: 410px;
  height: 450px;
  margin: 0 7px;
  display: flex;
  position: relative;
  cursor: pointer
}

.reclic-imgs img {
  width: inherit;

}

.img-title {
  width: 100%;
  height: 187px;
  position: absolute;
  bottom: 0;
  background-size: cover;
  background-image: url("@/imgs/img-back.png");
  font-size: 24px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  line-height: 320px;
  text-align: center;
  padding: 0 10px;
  box-sizing: border-box;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* 学习园地 */
.study-place {
  width: 100%;
  /* height: 30%; */
  overflow: hidden;
  background-image: url("@/imgs/图层 89.png");
  background-size: cover;
  padding: 3% 85px;
  box-sizing: border-box;
}

.changeTab {
  display: none;
}

.boxTit {
  display: block;
  text-align: center;
  margin: 0 auto 30px;
  color: #edc07b;
  font-size: 40px;
  font-weight: 400;
}

.top-place {
  width: 90%;
  display: flex;
  justify-content: space-around;
  margin-left: 5%;

}

.study-items {
  width: 30%;

}

.study-items:nth-child(2) {
  margin: 0 50px;
}

.study-title {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.study-title a {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.instudy-title {
  height: 35px;
  font-size: 24px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #f6be7f;
}

.study-more {
  height: 24px;
  font-size: 16px;
  font-family: Microsoft YaHei;
  color: #f6be7f;
  line-height: 30px;
  cursor: pointer
}

.big-item {
  width: 95%;
  background-color: #fff;
  display: flex;
  padding: 10px;
  cursor: pointer
}

.big-item img {
  width: 40%;
  /* height: 150px; */
}

.item-msgs {
  width: 60%;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 20px;
  color: #333333;
}

.item-msgs h2 {
  /* width: 280px; */
  width: 100%;
  height: 30px;
  white-space: nowrap;
  font-size: 22px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #333333;
  line-height: 32px;
  margin-bottom: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.item-msgs p {
  width: 100%;
  height: 90px;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #7d7b7a;
  line-height: 28px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.items-list {
  width: 100%;
  display: flex;
  cursor: pointer;
  /* margin-bottom: 10px; */
}

.small-arrows {
  width: 6px;
  height: 11px;
  margin-top: 10px;
}

.small-items {
  margin-top: 20px;
}

.sitems-msg {
  width: 100%;
  display: flex;
  white-space: nowrap;
  justify-content: space-around;
  margin-bottom: 20px;
}

.sitems-msg span:first-child {
  width: 58%;
  height: 25px;
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #f2f2f2;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sitems-msg span:last-child {
  /* width: 40%; */
  overflow: hidden;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #f2f2f2;
}

.img-detail {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  /* margin: 0 auto; */
}

.construction-imgs img {
  /* width: 40%; */
  /* height: 175px; */
  margin: 3px;
  border: 2px solid #edc07b;
  box-sizing: border-box;
  margin: 5px;
}

/* 旅游服务 */
.tourism {
  width: 100%;
  /* height: 600px; */
  background-color: #f3eddc;
  padding: 20px;
  box-sizing: border-box;
}

.tourism-title {
  width: 200px;
  height: 100px;
  margin: 0 auto;
  text-align: center;
}

.tourism-title h1 {
  width: 100%;
  height: 60px;
  font-size: 36px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #444343;
}

.tourism-title p {
  width: 100%;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #a7a4a3;

}

.plays {
  display: flex;
  margin: 1% 0 3% 9%;
}

.plays-item {
  width: 15%;
  overflow: hidden;
  position: relative;
  cursor: pointer
}

.plays-item p {
  width: 42px;
  font-size: 2rem;
  text-align: center;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #f2f2f2;
  position: relative;
  bottom: 40%;
  margin: 0 auto;
}

.plays-item img {
  width: 100%;
  display: block;
}

/* 飘窗 */
.windowPic {
  position: fixed;
  top: 20%;
  z-index: 998;
}
.float-left{
  left: 0;
}
.float-right{
  right: 0;

}
.center-yy{
  left: 50%;
  transform: translateX(-50%);
}

.windowPic img {
  width: 130px;
  display: block;
}
.center-yy img{
  width: 400px;
  display: block;
}
.close {
  width: 10%;
  text-align: right;
  font-size: 1rem;
  cursor: pointer;
  position: relative;
  top: 1rem;
  left: 85%;
  color: #fff;
  /* background-color: aqua; */
}
.close-yy{
  font-size: 2rem;
  width: 10%;
  top: 2rem;
  right: 10%;
}
.bg{
  background-color: rgba(0,0,0,0.7);
  /* opacity: 0.5; */
  position:fixed;
  /* width: 100%;
  height: 100vh; */
  left:0;
  right:0;
  top:0;
  bottom:0;
  z-index: 999;
}


@media screen and (min-width: 0px) and (max-width: 1000px) {
  .swiperBox {
    display: none;
  }

  .tab-news {
    color: #000;
    font-size: 26px;
    border-bottom: 4px solid #9c1306;
  }

  .notice-title {
    width: 65%;
    height: auto;
    font-size: 16px;
  }

  .title-right {
    height: 30px;
  }

  .tab-change span {
    width: 120px;
    font-size: 20px;
    line-height: 100px;
  }

  .rounder {
    width: 100%;
    overflow: hidden;
    margin-top: 110px;
  }

  .rounder img {
    text-align: center;
    width: 100%;
    height: 300px;
  }

  .comTime {
    display: block;
  }


  .atc {
    color: #edc07b;
    border-bottom: 6px solid #edc07b;
  }

  .announcement-right {
    display: none;
  }

  .left-title {
    padding: 0;
    box-sizing: border-box;
  }

  .announcement {
    padding: 0 5%;
  }

  .announcement-left {
    width: 100%;
    /* height: 440px; */
    margin-bottom: 30px;
    background-color: #fff;
  }

  .announcement-items {
    padding: 10px 0;
  }

  .announcement-items a {
    width: 100%;
    height: 20%;
    display: inline-flex;
    position: relative;
    font-size: 20px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #6e6d6d;
    padding: 15px 0;
    box-sizing: border-box;
    border-bottom: 1px solid #cecece;
  }

  .img-box {
    display: none;
  }

  .news {
    height: auto;
  }

  .new-message {
    padding: 0;
    margin: 0;
  }

  .roundBox {
    display: none;
  }

  .right-msgs {
    width: 100%;
    /* height: 1050px; */
    position: relative;
    top: 0px;
    left: 20px;
  }

  .more {
    display: none;
  }

  .boxTit {
    font-size: 25px;
  }

  .study-place {
    width: 100%;
    /* height: 30%; */
    overflow: hidden;
    background-image: none;
    background-color: #9c1306;
    background-size: cover;
    padding: 5% 0px;
    box-sizing: border-box;
  }

  .changeTab {
    width: 70%;
    display: flex;
    justify-content: space-around;
    font-size: 30px;
    font-weight: 400;
    color: #ffffff;
    margin-bottom: 20px;
  }

  .changeTab span {
    font-size: 20px;
  }

  .study-items:first-child {
    width: 100%;
    display: block;
  }

  .study-items {
    width: 100%;
    display: none;
  }

  .study-items:nth-child(2) {
    margin: 0;
  }

  .construction {
    display: none;
    width: 100%;
  }

  .img-detail {
    width: 100%;
    display: flex;
    justify-content: space-around;
    flex-wrap: nowrap;
  }

  .construction img {
    width: 35%;
  }

  .tourism {
    width: 100%;
    margin-bottom: 50px;
    background-color: #fff;
    padding: 0;
    box-sizing: border-box;
  }

  .tourism-title {
    width: 100%;
    height: 100px;
    text-align: center;
    margin: 0;
  }

  .tourism-title h1 {
    width: 100%;
    /* height: 60px; */
    line-height: 60px;
    font-size: 30px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #444343;
  }

  .plays {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin: 0;
  }

  .plays-item {
    width: 33%;
    /* height: 287px; */
    position: relative;
  }

  .plays-item img {
    width: 100%;
    height: 100%;
  }

  .plays-item p {
    width: 42px;
    font-size: 20px;
    text-align: center;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #f2f2f2;
    position: relative;
    bottom: 40%;
    margin: 0 auto;
  }

  .tab-change {
    width: 100%;
    height: 70px;
    display: flex;
    justify-content: space-between;
    padding-left: 50px;
    position: relative;
    left: -60px;
  }

  .mobile-more {
    display: block;
  }

  .tab-messages {
    margin-top: 20px;
  }



  .left-arrow {
    display: none;
  }

  .item-msg {
    width: 100%;
    height: 100%;
    margin-top: 20px;
    box-sizing: border-box;
  }

  .tabmsg-item {
    height: 10%;
    position: relative;
  }

  .msg-left {
    width: 35%;
    height: 100%;
    display: block;
  }

  .msg-left img {
    width: 35vw;
    height: auto;
    display: block;
  }

  .msg-right {
    width: 60%;
    height: 100%;
    margin-left: 20px;
    position: relative;
  }

  .msg-right span {
    display: block;
    /* height: 55%; */
    font-size: 1vw;
    font-weight: 400;
    color: #2c2b2b;
    white-space: pre-wrap;
    position: relative;
    top: 0;
    left: 0;
  }

  .items-date {
    font-size: 14px;
  }

  .item-date {
    font-size: 20px;
    position: relative;
    left: 38%;
    color: #343434;
  }


  .title-left {
    display: none;
  }

  .title-all {
    width: 110%;
    margin: 0 auto;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
  }

  .title-all h2 {
    text-align: center;
    height: auto;
    font-size: 20px;
    border-bottom: 2px solid #9c1306;
  }


  .msg-right span {
    height: 45%;
    font-size: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

}

@media screen and (min-width:0px) and (max-width: 425px) {
  .windowPic {
    display: none;
  }

  .item-date {
    left: 40%;
    font-size: 14px;
  }

  .msg-right span {
    height: 50%;
    overflow: hidden;
    font-size: 3.5vw;
  }
}

@media screen and (min-width:425px) and (max-width: 768px) {
  .windowPic {
    display: none;
  }

  .item-msgs h2 {
    font-size: 16px;
  }

  .item-msgs p {
    font-size: 14px;
    /* height: 90px; */
  }

  .plays-item p {
    width: 100%;
    display: block;
    font-size: 25px;
    text-align: center;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #f2f2f2;
    position: relative;
    bottom: 40%;
  }

  .serves img {
    display: block;
    width: 60%;
    height: 18%;
    margin-top: 45px;
    border-radius: 5%;
    box-shadow: #333333 5px 5px 5px;
  }

  .sitems-msg span:first-child {
    width: 60%;
    font-size: 16px;
  }

  .sitems-msg span:last-child {
    font-size: 16px;
  }
}

@media screen and (min-width: 1000px) and (max-width: 1200px) {
  .windowPic img {
    width: 70px;
    display: block;
  }
  .center-yy img{
    width: 300px;
    display: block;
  }

  .close {
    font-size: 10px;
  }
  .close-yy{
    font-size: 20px;
  }

  .phone span {
    font-size: 13px;
    display: block;
    margin: 1px 0;
    color: #ffff;
    white-space: nowrap;
  }

  .new-phone span {
    font-size: 13px;
  }

  .new-phone span:nth-child(2) {
    font-size: 13px;
    margin-top: 5px;
  }

  .tip-title {
    font-size: 28px;
    font-weight: 400;
    color: #fff;
  }

  .tip-msg {
    display: block;
    text-indent: 30px;
    width: 100%;
    /* height: 40%; */
    margin: 15px 0;
    font-size: 17px;
    font-weight: 400;
    color: rgb(149, 201, 220);
  }

  .tip-time {
    width: 80%;
    display: flex;
    justify-content: space-around;
    /* margin-bottom: 10px; */
  }

  .tip-line {
    display: block;
    width: 20px;
    margin: 25px 10px 0;
    height: 0;
    border: 1px solid #ffffff;
  }

  .times h2 {
    font-size: 27px;
    color: #fff;
  }

  .times p {
    font-size: 14px;
    text-align: center;
    color: #edc07b;

  }
}

@media screen and (min-width: 1200px) and (max-width: 1700px) {
  .windowPic img {
    width: 80px;
    display: block;
  }
  .center-yy img{
    width: 400px;
    display: block;
  }
  .close {
    font-size: 10px;
  }
  .close-yy{
    font-size: 20px;
  }
  .phone span {
    font-size: 1.2vw;
    display: block;
    margin: 1px 2px;
    color: #ffff;
    white-space: nowrap;
  }

  .phone span:nth-child(2) {
    /* font-size: 1.5vw; */
    margin-top: 5px;
  }

  .new-phone span {
    font-size:1.2vw;
  }

  .new-phone span:nth-child(2) {
    font-size: 1.5vw;
    margin-top: 5px;
  }

  .boxTit {
    font-size: 26px;
  }

  .top-place {
    transform: scale(1);
  }

  .study-place {
    padding: 2%;
  }

  .instudy-title {
    font-size: 16px;
  }

  .study-more {
    font-size: 14px;
  }

  .big-item {
    width: 100%;
  }

  .sitems-msg span {
    font-size: 16px !important;
  }

  .item-msgs h2 {
    font-size: 18px;
  }

  .item-msgs p {
    font-size: 16px;
    height: 60px;
  }

  .announcement {
    padding: 60px 5% 30px;
  }

  .new-message {
    padding: 50px 5%;
  }

  .sitems-msg span:first-child {
    width: 100%;
  }

  .sitems-msg span:last-child {
    /* font-size: 12px; */
    display: none;
  }

  .reclics {
    width: 100%;
    height: 40%;
    margin-top: 35px;
    background-image: url("@/imgs/文物巡礼 背景.png");
    background-size: cover;
    background-repeat: no-repeat;
    padding: 25px 10px 25px 20%;
    box-sizing: border-box;
    display: flex;
  }

  .reclic-imgs {
    height: auto;
    margin: 0 7px;
    display: flex;
    position: relative;
    cursor: pointer
  }

  .reclic-imgs img {
    width: 100%;
    height: 100%;
  }

  .img-title {
    font-size: 16px;
  }

  .visit-tips {
    width: 60%;
    padding: 2% 4%;
  }

  .visit-tips img {
    width: 100%;
    height: 100%;
    display: block;
  }

  .tip-title {
    font-size: 28px;
    font-weight: 400;
    color: #fff;
  }

  .tip-msg {
    display: block;
    text-indent: 30px;
    width: 100%;
    /* height: 40%; */
    margin: 15px 0;
    font-size: 17px;
    font-weight: 400;
    color: rgb(149, 201, 220);
  }

  .tip-time {
    width: 80%;
    display: flex;
    justify-content: space-around;
    /* margin-bottom: 10px; */
  }

  .tip-line {
    display: block;
    width: 20px;
    margin: 25px 10px 0;
    height: 0;
    border: 1px solid #ffffff;
  }

  .times h2 {
    font-size: 27px;
    color: #fff;
  }

  .times p {
    font-size: 14px;
    text-align: center;
    color: #edc07b;

  }




  .serves {
    padding: 40px 20px 0px;

  }

  .serves img {
    display: block;
    width: 100%;
    /* height: 15%; */
    margin-top: 45px;
    border-radius: 5%;
    box-shadow: #333333 5px 5px 5px;
  }

  .msg-right span {
    width: 90%;
    height: 30px;
    display: block;
    font-size: 20px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #343434;
    position: relative;
    top: -36px;
    left: 40px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .img-msgs {
    font-size: 16px;
    padding: 10px;

  }

  .left-round {
    transform: translateX(-10%);
  }
}
</style>

