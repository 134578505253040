<template>
  <router-view />
</template>

<style>
* {
  margin: 0;
  padding: 0;
  list-style: none;
  text-decoration: none;
}

</style> 
<script>
export default {
  created() {
    window.addEventListener('popstate', this.popstate, false);
  },
  destroyed() {
    window.removeEventListener('popstate', this.popstate, false);
  },
  methods: {
    popstate() {
      // localStorage.setItem('langtype', 'cn')
      sessionStorage.setItem('navindex', 0)
      location.reload()
    }
  }
}
</script>

