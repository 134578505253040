import { createRouter, createWebHashHistory, createWebHistory } from 'vue-router'
import HomeView from '../views/PC(cn).vue'



const router = createRouter({
  history: createWebHistory(), // 使用createWebHistory()创建history模式
  routes:
    [
      {
        path: '/',
        name: 'home',
        component: HomeView
      },
      {
        path: '/foreignlang',
        name: 'foreignlang',
        component: () => import('../views/PC(en).vue')
      },
      {
        path: '/single',
        name: 'single',
        component: () => import('../views/SinglePage.vue')
      },

      {
        path: '/essay/:item',
        name: 'essay',
        component: () => import('../views/EssayPage.vue')
      },
      {
        path: '/essaylist',
        name: 'essaylist',
        component: () => import('../views/EssayList.vue')
      },
      {
        path: '/search/:sword',
        name: 'search',
        component: () => import('../views/Searchresult.vue')
      },
      {
        path: '/listinfo-41-0.html',
        name: 'listinfo-41-0',
        component: () => import('../views/audio.vue')

      }
    ]
})



export default router
