<template>
  <div class="all">
    <div class="travell">
      <div class="left">
        <span>
          <img :src="require('@/imgs/时钟.png')" alt="" />
          开馆时间：9：00—17：00</span>
        <span>(周一闭馆)</span>
      </div>
      <div class="right">
        <span><img :src="require('@/imgs/旅客.png')" alt="" /> 当前在馆人数：
          <p>{{ inHall }}人</p>
        </span>
        <span>当前游览舒适度：{{ comfort }}</span>
      </div>
    </div>
    <div class="serve">
      <div>
        <router-link to="http://www.backlightvr.com/tour/0738e24d171e6b6a">
          <img :src="require('@/imgs/数字纪念馆.png')" alt="" />
          <p>数字纪念馆</p>
        </router-link>
      </div>
      <div>
        <a href="http://wjyl.lygjng.com/lygjng">
          <img :src="require('@/imgs/清明祭英烈.png')" alt="" @click="toworship" />
          <p>网祭英烈</p>
        </a>
      </div>
      <div>
        <a href="jqyy.lygjng.com/index/index/yy_wxjng">
          <img :src="require('@/imgs/预约服务.png')" alt="" />
          <p>预约服务</p>
        </a>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "travell",
  components: {},
  mixins: [],
  props: {},
  data() {
    return {
      comfort: "舒服",
      inHall: 0,
    };
  },
  computed: {},
  watch: {},
  beforeMount() {
    axios.get("/getp/total").then((res) => {
      this.inHall = res.data.split(":")[1].split("}")[0] * 1;
      if (this.inHall < 500) {
        this.comfort = "舒服";
      } else {
        this.comfort = "正常";
      }
    });
  },
  methods: {},
};
</script>
<style scoped>
* {
  margin: 0;
  padding: 0;
}

.all {
  display: none;
}

.travell {
  width: 100%;

  display: flex;
  border-bottom: 1px solid rgb(148, 148, 148);
}

.travell img {
  width: 5%;
}

.travell div {
  width: 50%;
  height: 100%;
  font-size: 1.2em;
  font-weight: 400;
  padding-top: 20px;
  box-sizing: border-box;
  padding-bottom: 5%;

}

.travell div span {
  width: 100%;
  display: block;
  font-size: 14px;
  text-align: center;
  color: #b93c48;
}

.travell div span p {
  display: inline-block;
  color: #daaa67;
}

.travell div span:nth-child(2) {
  color: #daaa67;
}

.serve {
  width: 100%;
  padding-top: 50px;
  margin: 0 auto;
  box-sizing: border-box;
  display: flex;
  justify-content: space-around;
  margin-bottom: 50px;
}

.serve div {
  width: 25%;
  height: 40%;
  text-align: center;
}

.serve div img {
  width: 80%;
  border-radius: 50%;

}

.serve p {
  width: 100%;
  height: 30px;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  color: #9c1306;
}

@media screen and (min-width: 0px) and (max-width: 1000px) {
  .all {
    display: block;
  }
}

@media screen and (min-width: 425px) and (max-width: 600px) {

  .travell div {
    width: 50%;
    height: 100%;
    /* font-size: 1.2em; */
    font-weight: 400;
    padding-top: 20px;
    box-sizing: border-box;
    border-bottom: 1px solid rgb(148, 148, 148);
  }

  .travell div span {
    width: 100%;
    display: block;
    font-size: 16px;
    text-align: center;
    color: #b93c48;
  }

  .travell div span p {
    display: inline-block;
    color: #daaa67;
  }

  .travell div span:nth-child(2) {
    color: #daaa67;
  }
}

@media screen and (min-width: 600px) and (max-width: 1000px) {

  .travell div {
    width: 50%;
    height: 100%;
    /* font-size: 1.2em; */
    font-weight: 400;
    padding-top: 20px;
    box-sizing: border-box;
    border-bottom: 1px solid rgb(148, 148, 148);
  }

  .travell div span {
    width: 100%;
    display: block;
    font-size: 20px;
    text-align: center;
    color: #b93c48;
  }

  .travell div span p {
    display: inline-block;
    color: #daaa67;
  }

  .travell div span:nth-child(2) {
    color: #daaa67;
  }
  .serve div img{
    width: 60%;
  }
  .serve p {
    width: 100%;
    height: 30px;
    text-align: center;
    font-size: 20px;
    font-weight: 400;
    color: #9c1306;
  }
}
</style>