<template>
  <div class="his-box">
    <div class="box-title">
      <h2>文物</h2>
      <p @click="getMore">更多>></p>
    </div>
    <div class="img-box">
      <div v-for="(item, index) in relicsList" :key="index" @click="tosingle(item)">
        <img :src="item.coverImage" alt="" />
        <span>{{ item.title }}</span>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "hisView",
  components: {},
  mixins: [],
  props: {},
  data() {
    return {
      arr: [],
      relicsList: [],
    };
  },
  computed: {},
  watch: {},
  beforeMount() {
    // this.arr = localStorage.getItem('atcList') == null ? this.getData() : JSON.parse(localStorage.getItem('atcList'));
    this.relicsList = localStorage.getItem('mobileHis') == null ? this.getList() : JSON.parse(localStorage.getItem('mobileHis'));
    axios.post(`${process.env.VUE_APP_BASE_API}/door/ArticleList`).then((res) => {
        this.arr = res.data.rows;
        localStorage.setItem("atcList", JSON.stringify(this.arr));

        this.getList();
      });
  },
  methods: {
    getData() {
     
    },
    tosingle(item) {
      localStorage.setItem("indexList", "文物");
      this.$router.push(`/essay/${item.id}`).then(() => {
        location.reload();
      });
    },
    getMore() {
      localStorage.setItem("indexList", this.relicsList[0].category.categoryName);
        sessionStorage.setItem('listData', JSON.stringify(this.relicsList[0].category))
      this.$router.push(`/essaylist`).then(() => {
        location.reload();
      });
    },
    getList() {
      let testarr = [];
      this.arr.forEach((element) => {
        if (element.tags == "50") {
          if (
            element.category.categoryName == "文物" &&
            element.coverImage != ""
          ) {
            element.createTime = element.createTime.split(" ")[0];
            element.coverImage = `${process.env.VUE_APP_BASE_API}` + element.coverImage;
            testarr.push(element);
          }
        }
      });
      this.relicsList = testarr.splice(0, 4);
      localStorage.setItem("mobileHis", JSON.stringify(this.relicsList));
    },
  },
};
</script>
<style scoped>
.his-box {
  width: 100%;
  display: none;
  margin: 30px 0;
}

.img-box {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.box-title {
  width: 105%;
  padding: 0 20px;
  box-sizing: border-box;
  justify-content: space-between;
  display: flex;
  margin-bottom: 20px;
}

.box-title h2 {
  /* width: 100px; */
  /* height: 50px; */
  text-align: center;
  font-size:20px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #2a2a2a;
  border-bottom: 5px solid #9c1306;
}

.box-title p {
  width: 80px;
  height: 40px;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #bbb7b7;
  line-height: 40px;
}

.img-box {
  width: 100%;
  height: 140vh;
}

.img-box div {
  width: 45%;
  height: 45%;
  box-shadow: 0px 2px 10px #929292;
}

.img-box img {
  width: 100%;
  height: 80%;
  display: flex;
}

.img-box span {
  width: 95%;
  display: block;
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #2c2b2b;
  padding: 5px ;
  text-align:left;
  overflow: hidden;
  text-overflow: ellipsis;
  /* white-space: nowrap; */
}

@media screen and (min-width: 0px) and (max-width: 425px) {
  .img-box {
    width: 100%;
    height: 80vh;
    /* padding: 0 5px; */
    margin: 0 auto;
  }

  .img-box div {
    width: 47%;
    height: 45%;
    box-shadow: 0px 2px 10px #929292;

  }

  .img-box img {
    width: 100%;
    height: 67%;
    display: flex;
  }
  .img-box span{
    font-size: 16px;
  }

}

@media screen and (min-width: 0px) and (max-width: 992px) {
  .his-box {
    display: block;
  }

}</style>